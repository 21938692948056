@import url("https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Outfit:wght@100..900&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  font-family: "Outfit", sans-serif !important;
}

.londrina {
  font-family: "Londrina Outline", sans-serif !important;
}

.quantico {
  font-family: "Quantico", sans-serif !important;
}
