.rc-accordion-toggle {
    /* display: flex; */
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background-color: #141041;
    transition: 0.3s;
}
.rc-accordion-toggle.active{
    background-color: #141041;
}
.rc-accordion-toggle.active .rc-accordion-icon{
    transform: rotate(180deg);
    color: #fff;
}
.rc-accordion-card{
    /* border: 1px solid #ddd; */
    border-radius: 15px;
    margin-bottom: 10px;
    overflow: hidden;
}
.rc-accordion-card:last-child{
    margin-bottom: 0;
}
.rc-accordion-title{
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #475F7B;
    transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
    color: #fff;
}
.rc-accordion-icon{
    position: relative;
    top: 2px;
    color: #475F7B;
    transition: 0.35s;
    font-size: 12px;
}
.rc-accordion-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}
.rc-accordion-body p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}
.rc-collapse{
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.rc-collapse.show{
    height: auto;
    background: #2b3a74;
}

.card-border{
    border: 1px solid rgba(171,196,255,.5);
    border-radius: 15px;
}
/* .btn-border{
    border: 0 solid #e5e7eb;
} */
.acc-btn{
    color: #3bd0d8;
    border-color: #58f3cd;
    background-image: linear-gradient(var(--gradient-rotate, 90deg),rgba(59,208,216,.2), rgba(59,208,216,0));

    /* background-color: rgba(59,208,216,.2);
    background-color: rgba(59,208,216,0); */

}
.acc-btn::before{
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: .7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 var(--border-line-width,1.5px) var(--border-color);
    -webkit-mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
    mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
}
.frosted-glass-teal {
    /* --text-color: #fff;
    --border-color: #fff;
    --bg-board-color: hsla(0,0%,100%,.12);
    --bg-board-color-2: hsla(0,0%,100%,0); */
}
.frosted-glass-teal {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 0.75rem !important;
    --text-color: #fff;
    --border-color: #0F0F0F;
    --bg-board-color: #0F0F0F;
    --bg-board-color-2: #0F0F0F;
    position: relative;
    -webkit-backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    color: var(--text-color);
    background: linear-gradient(162deg,var(--bg-board-color) 28.7%,var(--bg-board-color-2,var(--bg-board-color)));
    isolation: isolate;
}
.clickable-filter-effect:hover {
    filter: brightness(.95);
    -webkit-backdrop-filter: brightness(.95);
    backdrop-filter: brightness(.95);
}
@media only screen and (max-width:500px){
    .acc-btn{
        font-size: 11px;
    }
    .nav-pills .nav-link {
        font-size: 12px;
    }
}
@media only screen and (max-width:769px){
    .mobile-scroll{
        display: grid;
        white-space: nowrap;
        overflow-y: scroll;
        width: auto;
    }
}