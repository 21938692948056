@import url(https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Outfit:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Londrina+Outline&family=Outfit:wght@100..900&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap);
.rc-accordion-toggle {
    /* display: flex; */
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background-color: #141041;
    transition: 0.3s;
}
.rc-accordion-toggle.active{
    background-color: #141041;
}
.rc-accordion-toggle.active .rc-accordion-icon{
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    color: #fff;
}
.rc-accordion-card{
    /* border: 1px solid #ddd; */
    border-radius: 15px;
    margin-bottom: 10px;
    overflow: hidden;
}
.rc-accordion-card:last-child{
    margin-bottom: 0;
}
.rc-accordion-title{
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #475F7B;
    transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
    color: #fff;
}
.rc-accordion-icon{
    position: relative;
    top: 2px;
    color: #475F7B;
    transition: 0.35s;
    font-size: 12px;
}
.rc-accordion-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}
.rc-accordion-body p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}
.rc-collapse{
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.rc-collapse.show{
    height: auto;
    background: #2b3a74;
}

.card-border{
    border: 1px solid rgba(171,196,255,.5);
    border-radius: 15px;
}
/* .btn-border{
    border: 0 solid #e5e7eb;
} */
.acc-btn{
    color: #3bd0d8;
    border-color: #58f3cd;
    background-image: linear-gradient(90deg,rgba(59,208,216,.2), rgba(59,208,216,0));
    background-image: linear-gradient(var(--gradient-rotate, 90deg),rgba(59,208,216,.2), rgba(59,208,216,0));

    /* background-color: rgba(59,208,216,.2);
    background-color: rgba(59,208,216,0); */

}
.acc-btn::before{
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: .7;
    background: transparent;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1.5px var(--border-color);
    box-shadow: inset 0 0 0 var(--border-line-width,1.5px) var(--border-color);
    -webkit-mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
    mask-image: radial-gradient(at -31% -58%,rgba(0,0,0,.5) 34%,transparent 60%),linear-gradient(270deg,rgba(0,0,0,.2) 0,transparent 13%),linear-gradient(rgba(0,0,0,.05),rgba(0,0,0,.05));
}
.frosted-glass-teal {
    /* --text-color: #fff;
    --border-color: #fff;
    --bg-board-color: hsla(0,0%,100%,.12);
    --bg-board-color-2: hsla(0,0%,100%,0); */
}
.frosted-glass-teal {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 0.75rem !important;
    --text-color: #fff;
    --border-color: #0F0F0F;
    --bg-board-color: #0F0F0F;
    --bg-board-color-2: #0F0F0F;
    position: relative;
    -webkit-backdrop-filter: blur(calc(var(--blur-size) * (-1 * 0 + 1)));
    -webkit-backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    backdrop-filter: blur(calc(var(--blur-size) * (-1 * 0 + 1)));
    backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    color: var(--text-color);
    background: linear-gradient(162deg,var(--bg-board-color) 28.7%,var(--bg-board-color));
    background: linear-gradient(162deg,var(--bg-board-color) 28.7%,var(--bg-board-color-2,var(--bg-board-color)));
    isolation: isolate;
}
.clickable-filter-effect:hover {
    -webkit-filter: brightness(.95);
            filter: brightness(.95);
    -webkit-backdrop-filter: brightness(.95);
    backdrop-filter: brightness(.95);
}
@media only screen and (max-width:500px){
    .acc-btn{
        font-size: 11px;
    }
    .nav-pills .nav-link {
        font-size: 12px;
    }
}
@media only screen and (max-width:769px){
    .mobile-scroll{
        display: grid;
        white-space: nowrap;
        overflow-y: scroll;
        width: auto;
    }
}
.accordion {
    --bs-accordion-bg: #161819 !important;
    --bs-accordion-border-width: 0px !important;
}
.accordion-body{
    border: 1px solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.accordion-button:not(.collapsed) {
    background-color: #0f0f0f !important;
    color: #ffffff !important;
}
.accordion-button::after {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
    }
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 10px !important;
}
.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
    border-radius: 10px !important;
}
.accordion-button {
    background: #0f0f0f !important;
    color: #ffffff !important;
}
a {
    color: rgb(0, 0, 0)!important;
    text-decoration: underline;
}
.accordion-button:focus {
    border-color:none !important;
    box-shadow:none !important;
}
/* img, svg {
    vertical-align: baseline !important;
} */
.slick-slider {
    margin: 0 auto;
  }
  
  .MuiCard-root {
    margin: 16px;
  }
.bg-card-bg {
    background-image: linear-gradient(140.14deg,rgba(0,182,191,.15),rgba(27,22,89,.1) 86.61%),linear-gradient(321.82deg,#18134d,#1b1659);
    border-radius: 1.25rem;
}
.text-logo-color{
    --tw-text-opacity: 1;
    color: rgb(171 196 255/var(--tw-text-opacity));
}
.text-color {
    color: rgba(196,214,255,.5)!important;
}
.text-xs-swap {
    font-size: .75rem;
    line-height: 1rem;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-p-color {
    --tw-text-opacity: 1;
    color: rgb(218 46 239/var(--tw-text-opacity));
}
* {
  font-family: "Outfit", sans-serif !important;
}

.londrina {
  font-family: "Londrina Outline", sans-serif !important;
}

.quantico {
  font-family: "Quantico", sans-serif !important;
}

.top9 {
  top: 9px;
}
.top6 {
  top: 6px;
}
.top2 {
  top: 2px !important;
}
.rounded-lg {
  border-radius: 1rem !important;
}
.margin-top-4 {
  margin-top: 3.6rem;
}
.width30 {
  width: 30px;
}
.width77 {
  width: 77px !important;
}
.width64 {
  width: 64px;
}

.width116 {
  width: 116px;
}

.text-shadow {
  text-shadow: 1px 1px 3px #333;
}

/* .minHeightCard {
    min-height: 165px;
  } */

.text-gredient {
  background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
  background-image: linear-gradient(313deg, dodgerblue, #d257d2, red);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-gray {
  background-color: #303232 !important;
}
.tokenlisting-img .img1 {
  width: 35px !important;
  border-radius: 50%;
  position: relative;
  /* background:linear-gradient(126.6deg, #2db3a042 28.69%, #65e6d442 100%); */
  border: 2px solid #000;
  /* background: #000; */
}
.tokenlisting-img .img2 {
  width: 35px !important;
  /* background:linear-gradient(126.6deg, #2db3a042 28.69%, #65e6d442 100%); */
  border-radius: 50%;
  position: relative;
  right: 10px;
  margin-right: 4px;
  z-index: 1;
  border: 2px solid #000;
  background: #000;
}

.bg-purplegrad {
  background: linear-gradient(260deg, #f705d9, #2662b5) !important;
  color: #fff;
}

.bg-darker {
  background-color: #212121;
}
.btn-gray {
  background-color: #373b40;
  color: #fff;
  border-color: #373b40;
}

.mb-half {
  margin-bottom: 0.15rem !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}
.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.bg-purple {
  background-color: #30907f;
}

.text-cyan {
  color: #36b369;
}

.text-lgray {
  color: #a7a7a7 !important;
}

.bg-cyan {
  background: linear-gradient(270deg, #ff044a, #c61ca3) !important;
  color: #fff !important;
  border: #c61ca3 !important;
}

.border-cyan {
  border: 1px solid #2a6e62 !important;
}
.border-light {
  border: 1px solid #343434 !important;
}

.counting {
  border: 1px solid #363636 !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0.4rem 0.8rem 0.4rem 12px !important;
  align-self: center;
  color: #fff !important;
}

.badge-outline {
  border: 1px solid #1ba5d4 !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0.2rem 0.8rem 0.2rem 12px;
  align-self: center;
}
.counting-purple {
  border-color: #913db8 !important;
}

.badge-outline-primary {
  border-color: #1ba5d4 !important;
}

.terms_condition strong {
  color: #1ba5d4 !important;
}

.terms_condition strong:first {
  color: #1ba5d4 !important;
}

.sp-border {
  width: 1rem !important;
  height: 1rem !important;
}

.badge-outline-blue,
.counting-blue {
  border-color: #1e4fad !important;
}

.badge-outline-purple {
  border-color: #3541df !important;
}
.social_wrapper_dropzone .input-group-text {
  border-color: #363636 !important;
}

.social-media-wrapper {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.social-media-wrapper .input-group-text {
  border-color: #363636 !important;
}

.social-media-wrapper img {
  height: 30px;
  margin-left: -3px;
}

.social-media-wrapper button {
  background-color: transparent;
  border: 0;
  margin-left: -10px;
}

/* .min-width-70 {
      min-width: 73%!important;
  } */

.content-fold {
  padding: 3.5rem !important;
}

.border-right {
  border-right: 1px solid #413f3f;
}

.rounded-btn {
  border-radius: 1.2rem !important;
}

.bg-blue {
  background-color: #172448 !important;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: rgba(163, 122, 122, 0.08);
  color: #fff;
}

.rewardwrapper {
  min-height: 250px;
  max-height: auto;
  margin-top: 8rem;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-wide {
  overflow: visible !important;
}

.width180 {
  width: 180px;
}

.proposal-bg {
  background-color: #1a1d1f;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-top: 2px solid #65e6d4;
  color: #37665f;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
  border-top: 2px solid #65e6d4;
  color: #fff;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  background-color: #3f3f3f !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-weight: normal;
  color: #fff;
  background: #272a2c;
  margin: 2px;
  white-space: nowrap;
  border: 1px solid transparent !important;
}

.tab-scroller .nav-tabs .nav-item.show .nav-link,
.tab-scroller .nav-tabs .nav-link {
  margin: 0 2px;
}

.nav-tabs {
  border-bottom: none;
  margin: -1px;
}

/* ========Table style ==========*/

.ana_label {
  font-size: 12px;
  color: #84858b;
  clear: both;
  font-style: italic;
}

.ana-tb-list {
  display: table;
  width: 100%;
  font-size: 13px;
  color: #fff;
}
.top-pair-table .ana-tb-item .ana-tb-col:nth-child(2) {
  width: 250px;
}
/* .exchangeTable .ana-tb-item .ana-tb-col:nth-child(1){
    width:300px
    } */

.ana-tb-item {
  transition: 0.4s ease;
  display: table-row;
  overflow: auto;
  /* box-shadow: 0 0.125rem 0.25rem rgb(0 0 0); */
}

.ana-tb-item:not(.ana-tb-head):hover,
.ana-tb-item:not(.ana-tb-head).seleted {
  background: transparent;
  box-shadow: 0 2px 15px -4px rgb(0 0 0 / 40%);
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.bg-back-colorN {
  background-color: #00000021 !important;
}

.ana-tb-col-Token {
  position: relative;
  display: table-cell;
  vertical-align: middle !important;
  padding: 0.6rem 2.3rem !important;
  white-space: nowrap !important;
  overflow: auto;
}

.ana-tb-col {
  position: relative;
  display: table-cell;
  vertical-align: middle !important;
  padding: 0.5rem 0.6rem !important;
  white-space: nowrap !important;
  overflow: auto;
}
#profit_calc .ana-tb-col {
  padding: 0.3rem 0.5rem !important;
}

.ana-tb-col:first-child {
  padding-left: 1.25rem;
}

.ana-tb-col:last-child {
  padding-right: 1.25rem;
}

.ana-tb-head .ana-tb-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #333;
  font-size: 0.9em;
  border-bottom: 1px solid rgba(219, 223, 234, 0.9);
  vertical-align: middle !important;
  padding: 0.7rem 0.5rem !important;
}

.ana-tb-list.is-separate {
  /* margin-top: -8px; */
  border-collapse: separate;
  border-spacing: 0 5px;
}

#profit_calc .ana-tb-list.is-separate {
  border-spacing: 0 2px;
}
.history_tbl.ana-tb-list.is-separate {
  border-spacing: 0 5px;
}

.ana-tb-list.is-separate .ana-tb-item > .ana-tb-col {
  background: #1a1d1f;
  /* border: none; */
  /* box-shadow: 0px 1px 3px 1px rgba(54, 74, 99, .05) */
}
#profit_calc .ana-tb-list.is-separate .ana-tb-item > .ana-tb-col {
  background: #272a2c;
  /* border: none; */
  /* box-shadow: 0px 1px 3px 1px rgba(54, 74, 99, .05) */
}

.ana-tb-list.is-separate .ana-tb-head > .ana-tb-col {
  background: #c2ebe2;
  border: none;
  color: #fff;
  font-weight: 600;
}

.ana-tb-list.is-separate .ana-tb-item > .ana-tb-col:first-child {
  border-radius: 4px 0 0 4px;
}

.ana-tb-list.is-separate .ana-tb-item > .ana-tb-col:last-child {
  border-radius: 0 4px 4px 0;
}

.ana-tb-list.is-separate .ana-tb-item:hover .ana-tb-action-hidden {
  background: #474777;
}

.ana-tb-list.is-separate .ana-tb-head .ana-tb-col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.quickbuy_wrapper {
  margin-top: 25px;
}

.btn_icon {
  width: 45px;
  height: 45px;
  color: #fff !important;
  cursor: pointer;
  padding: 7px !important;
}

.l_btn {
  cursor: pointer;
}

._icon {
  color: #979797;
  font-size: 18px;
}

._icon path {
  stroke: #979797;
}

.lp_info {
  border: 1px solid #62817d;
  padding: 10px;
  border-radius: 10px;
  margin: 15px 0px 5px;
}

.left_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.logo-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #2c2a2a;
  margin: 12px;
}

/* .logo-img img {
      position: relative;
      top: 17%;
      left: 25%;
      width: 45%;
      padding: 5px;
      margin: 0 auto;
  } */

.logo-img img {
  position: relative;
  top: 0%;
  left: 0%;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
}

.inactive_div {
  background: #333;
  /* z-index: 8; */
  pointer-events: none;
  /* overflow: auto; */
  opacity: 0.4;
}

.left_div {
  position: absolute;
  top: 0;
  text-align: center;
  left: 0;
  /* width: 200px; */
  height: 115px;
}

.right_div {
  position: absolute;
  top: 0;
  text-align: center;
  /* width: 200px; */
  height: 115px;
  right: 0;
}

.leaderboard_footer {
  display: flex;
  justify-content: space-between;
}

.leaderbrd_modal_footer {
  display: flex;
  justify-content: flex-start;
  grid-gap: 40px;
  gap: 40px;
}

.level_blur {
  -webkit-filter: blur(2px);
          filter: blur(2px);
  pointer-events: none;
}

.close_button {
  float: right;
  top: 10px;
  right: 10px;
  position: absolute;
}

.width90 {
  width: 90px !important;
}

.width110 {
  width: 110px !important;
}

.nav_divider {
  color: #666;
  text-align: left;
  font-size: 14px;
  position: relative;
  width: 126px;
  margin: 10px 7px auto;
}

.bg-dark-gray {
  background-color: #1a1d1f !important;
}

/* Top MOvers */
.circle_btn {
  width: 32px;
  height: 32px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
  border-radius: 50%;
  background-color: #545454;
  color: #fff;
  text-align: center;
}
.circle_btn i {
  position: relative;
  top: 20%;
  color: #fff;
}

.brands {
  position: relative;
  overflow: hidden;
  height: 6.6rem;
}

.brands:hover .brands__wrapper,
.brands:hover .brands__preWrapper {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.brands__preWrapper {
  position: relative;
  width: 100%;
  height: 8.1rem;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-animation: scroll1 10s linear infinite;
          animation: scroll1 10s linear infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  animation-direction: alternate-reverse;
  will-change: transform;
}

.brands__wrapper {
  position: absolute;
  white-space: nowrap;
  -webkit-transform: translateX(0%) translateZ(0);
          transform: translateX(0%) translateZ(0);
  -webkit-animation: scroll2 10s linear infinite;
          animation: scroll2 10s linear infinite;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  animation-direction: alternate-reverse;
  will-change: transform;
}

.brands__wrapper > a {
  position: relative;
  display: inline-block;
  margin: 0 1rem;
  vertical-align: middle;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.455em !important;
  vertical-align: 0.155em !important;
}

/* =============Marquee home page============= */

.img_marquee_container {
  margin-bottom: 1.5rem;
}

.img_marquee_container:nth-child(2) {
  margin-left: -53px;
}

.img_marquee_container:nth-child(3) {
  margin-left: -10px;
}

.img_marquee_container img {
  width: 70px;
  height: 70px;
  margin: 0 1rem;
  background-color: #1f1b1b;
  border-radius: 18px;
  padding: 1px;
}

.img_marquee {
  position: relative;
  overflow: hidden;
  height: 5.1rem;
}

/* .img_marquee:hover .img_marquee__wrapper,
  .img_marquee:hover .img_marquee__preWrapper {
    animation-play-state: paused;
  } */
/* 
  .img_marquee__preWrapper {
    position: relative;
    width: 100%;
    height: 5.1rem;
    transform: translateX(0%);
    animation: scroll1 15s linear infinite;
    animation-delay: 1s;
    animation-direction: alternate-reverse;
    will-change: transform;
  }
  
  .img_marquee__wrapper {
    position: absolute;
    white-space: nowrap;
    transform: translateX(0%) translateZ(0);
    animation: scroll2 15s linear infinite;
    animation-delay: 1s;
    animation-direction: alternate-reverse;
    will-change: transform;
  }
  
  .img_marquee__wrapper > img {
    position: relative;
    display: inline-block;
    margin: 0 0.9rem;
    vertical-align: middle;
    width: 65px;
    height: 65px;
    border-radius: 18px;
    border: 1px solid #85858575;
    background-color: #3b3b3b;
  }
  
   .img_marquee:nth-child(2) .img_marquee__wrapper > img {
    margin: 0 1.2rem;
  }   */

.img_outer {
  margin: 0 auto;
  width: 55px;
  height: 52px;
  background: #333;
  border-radius: 3px;
  padding: 5px;
  border: 1px solid #393939;
  box-shadow: 3px 3px -2px #d09;
}

.img_outer i {
  /* width: 47px; */
  text-align: center;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-21px, 8px);
          transform: translate(-21px, 8px);
}

.display_sm {
  display: flex;
  justify-content: center;
}

.display_content_between {
  display: flex;
  justify-content: space-between;
}

.verify_token_main {
  display: flex;
  justify-content: space-between;
}

.seedx_dao_section .form-control {
  padding: 0.275rem 0.75rem !important;
}

.verify-page-content {
  height: 65vh;
  overflow-y: auto;
  position: relative;
  z-index: 999;
}

.payment_img img {
  width: 172px;
}

.affilaite-card h4.card-title {
  background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
  background-image: linear-gradient(45deg, dodgerblue, purple, red);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@-webkit-keyframes scroll1 {
  0% {
    -webkit-transform: translateX(-0%);
            transform: translateX(-0%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes scroll1 {
  0% {
    -webkit-transform: translateX(-0%);
            transform: translateX(-0%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes scroll2 {
  0% {
    -webkit-transform: translateX(0%) translateZ(0);
            transform: translateX(0%) translateZ(0);
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(0);
            transform: translateX(-100%) translateZ(0);
  }
}

@keyframes scroll2 {
  0% {
    -webkit-transform: translateX(0%) translateZ(0);
            transform: translateX(0%) translateZ(0);
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(0);
            transform: translateX(-100%) translateZ(0);
  }
}

@media only screen and (max-width: 640px) {
  .icon-link svg {
    width: 30px !important;
    height: 30px !important;
  }
  .affilaite-card .card-content {
    padding: 15px;
    /* text-align: center; */
    min-height: 100px;
  }

  .affilaite-card h4.card-title {
    font-size: 15px;
    background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
    background-image: linear-gradient(45deg, dodgerblue, purple, red);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .affilaite-card .card-content p {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }
  .affilaite-card .card-icon-large {
    display: none;
  }

  .ml-left {
    margin-left: auto;
  }

  .left_card {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: unset !important;
  }

  .table_design {
    display: revert !important;
  }

  .gap-board {
    display: list-item !important;
  }

  .display_sm {
    display: grid;
    text-align: center;
  }
  .ana-label-mobile {
    display: none;
  }
  .mobile-font {
    font-size: 1rem;
  }
  .tab-width {
    min-width: 120px;
  }
  .ana-fs-10 {
    font-size: 10px;
  }
  .ana-fs-10 .ana_label {
    font-size: 10px;
  }
  .ana-fs-10 .mob-font {
    font-size: 10px !important;
  }
  .ana-fs-10 .img_bg {
    width: 17px;
    height: 17px;
    display: flex;
    /* justify-content: center;
      align-items: center; */
  }
  .ana-fs-10 .farm_img_1,
  .ana-fs-10 .farm_img_2 {
    width: 15px;
    height: 15px;
  }
}

@media screen and (min-width: 481px) {
  .modal-fullscreen {
    height: auto !important;
  }
  .mob_label_hidden {
    display: none;
  }

  .display_portfolio {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 480px) {
  .mr-fr_slider {
    margin-right: 15px;
  }

  #carouselLaunchpad .carousel-indicators {
    bottom: 77%;
    right: -94px;
    justify-content: end;
  }
}
@media screen and (max-width: 480px) {
  .form-control {
    font-size: 16px !important;
  }
  input[type='number'],
  input[type='radio'],
  input[type='text'],
  input[type='password'] {
    font-size: 16px !important;
  }

  .mob_width_204 {
    width: 204px;
  }
  .currency_carousel .react-multi-carousel-item {
    margin-bottom: 25px;
  }

  .launchpad_main_section .text-end {
    text-align: left !important;
  }
  .to_make_vcenter {
    margin-top: 30px !important;
  }

  #carouselLaunchpad .carousel-indicators {
    right: -30px !important;
  }
  .dao_banner .carousel-inner {
    height: 100px;
  }
  .dao_banner .carousel-inner .carousel-item:nth-child(1) h3 {
    line-height: 100px;
  }
  .bg-accordion-card {
    padding: 0.8rem !important;
  }
  .p_above_border {
    padding-top: 1rem !important;
  }
  .img_marquee__preWrapper {
    -webkit-animation: scroll1 25s linear infinite !important;
            animation: scroll1 25s linear infinite !important;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .img_marquee__wrapper > img {
    margin: 0 0.8rem;
  }

  .img_marquee:nth-child(2) .img_marquee__preWrapper {
    -webkit-animation: scroll1 20s linear infinite !important;
            animation: scroll1 20s linear infinite !important;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }

  /* ============marquee on home page============ */

  .img_marquee_container {
    margin-bottom: 0.8rem;
  }

  .img_marquee_container img {
    width: 60px;
    height: 60px;
    margin: 0 0.6rem;
  }

  .img_marquee_container:nth-child(2) {
    margin-left: -38px;
  }

  /* ============marquee on home page end============ */

  .img_marquee__preWrapper {
    -webkit-animation: scroll1 25s linear infinite !important;
            animation: scroll1 25s linear infinite !important;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .img_marquee__wrapper > img {
    margin: 0 0.8rem;
  }

  .img_marquee:nth-child(2) .img_marquee__preWrapper {
    -webkit-animation: scroll1 20s linear infinite !important;
            animation: scroll1 20s linear infinite !important;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }

  .img_marquee:nth-child(2) .img_marquee__wrapper > img {
    margin: 0 0.4rem;
  }

  .img_marquee__wrapper {
    -webkit-animation: scroll2 25s linear infinite !important;
            animation: scroll2 25s linear infinite !important;
  }

  .img_marquee__wrapper {
    -webkit-animation: scroll2 25s linear infinite !important;
            animation: scroll2 25s linear infinite !important;
  }

  .feature-para {
    padding: 1rem 0;
  }

  .info_top_area .text-end {
    text-align: left !important;
  }

  .verify-page-content {
    height: 80vh !important;
  }
  .custom-footer {
    bottom: -154px;
  }
  .tokenBackdrop {
    background-size: auto !important;
  }
  /* .socialmediabutton div + div {
      display: none;
    } */

  .invite_earn_section .text-end {
    text-align: center !important;
  }

  .page-wrapper {
    padding-bottom: 10px !important;
  }
  .info_page_table .width30 {
    width: 26px;
  }

  /* .brands {
      height: 5.5rem;
    } */
  .brands__preWrapper {
    height: 5.5rem;
  }
  .profile-layout {
    display: grid !important;
    height: 126px !important;
  }
  /* 
    .profile__picture {
      position: relative;
      top: 25%;
      left: 28% !important;
    
      border-radius: 50% !important;
    } */

  .profile__picture img {
    border-radius: 50%;
  }
  .address {
    text-align: center;
    padding-top: 13px;
  }

  .stats-info {
    margin-top: 9rem !important;
  }

  .seedx_dao .col:last-child {
    width: unset !important;
  }
  .seedx_dao .col {
    width: unset !important;
  }
  .seedx_dao .btn-grad2 {
    font-size: 12px !important;
  }
  .stats-info {
    margin-top: 1rem;
  }

  .rewardwrapper {
    margin-top: 1rem !important;
  }

  .tokenBackdrop {
    padding: 15px 0 0px !important;
  }

  .ScratchCard__Result h4 {
    font-size: 2rem;
    padding: 3rem;
    color: #2e848f !important;
  }

  .coupon-used h3 {
    font-size: 1.2rem !important;
  }
  .coupon-used p {
    font-size: 0.7rem !important;
  }
  .coupon-used div {
    font-size: 0.7rem !important;
  }

  .availibility {
    padding: 0.5rem !important;
    font-size: 12px !important;
  }

  .card-padding-mob {
    padding: 12px 0;
  }

  .text-sm-center {
    text-align: center;
  }

  .border-on-mobile {
    border-left: 1px solid rgb(158 158 158 / 18%) !important;
  }
  .mtop10 {
    margin-top: 10px !important;
  }
  .p10 {
    padding: 2rem 10px 0;
  }

  .mob_margin_bottom {
    margin-bottom: 20px !important;
  }

  .mt-sm-5 {
    margin-top: 2.2rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.2rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.2rem !important;
  }

  .ana-tb-col {
    padding: 0.2rem 0.2rem !important;
  }

  .info_page_table .ana-tb-col {
    padding: 0.2rem 0.6rem !important ;
  }
  .ana-tb-list.is-separate.info_page_table {
    border-spacing: 0 6px;
  }

  .img-container {
    padding: 30px;
    width: 100% !important;
    right: 0;
  }

  .farm-table-logo {
    width: 15px !important;
  }

  .modal-dialog {
    max-width: unset !important;
  }

  .social {
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }

  .sidebar-overflow {
    max-height: 60vh !important;
    min-height: 310px !important;
    width: 100% !important;
  }

  .overflow-wrapper-buysell {
    height: 410px !important;
  }

  .overflow-wrapper-orderbook {
    height: 181px !important;
  }

  .last_price_wrapper {
    padding: 1rem 0.3rem !important;
  }

  .price-down,
  .price-up {
    font-size: 16px !important;
  }

  .mpad {
    padding-left: 4px !important;
  }

  .calign {
    text-align: center !important;
  }

  .limitcard .nav-pills .nav-link {
    padding: 0.5rem 0.3rem;
  }

  .mob-padding {
    padding: 5px !important;
  }

  /* .currency-header-left {
          border-right: 1px solid transparent !important;
      } */
  .currency-header-right {
    padding: 10px 0px !important;
  }

  .currency-header-left {
    padding: 13px 8px !important;
  }

  .quickbuy_wrapper {
    padding: 0.5rem !important;
    margin-top: 0 !important;
  }

  .quickbuy_wrapper .padding-0 {
    padding: 0 !important;
  }

  .quickbuy_wrapper .fs-12 {
    font-size: 10px !important;
  }

  .quickbuy_wrapper .fs-16 {
    font-size: 14px !important;
  }

  .circle_bg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-right: 2px !important;
  }

  .inside_img {
    width: 1rem !important;
    height: 1rem !important;
    -webkit-transform: translate(4px, 2px) !important;
            transform: translate(4px, 2px) !important;
  }

  .max-min-btn {
    font-size: 0.55rem !important;
    line-height: 0.5rem !important;
  }

  .scratch-card-inner:not(.modal .scratch-card-inner) {
    height: 216px !important;
  }

  .static_coupon_card .scratch-card-inner img {
    height: 125px !important;
    width: 100%;
  }

  .ScratchCard__Result:not(.modal .ScratchCard__Result) {
    padding: 0.5rem !important;
    height: 126px !important;
    font-size: 10px;
  }

  .ScratchCard__Result i:not(.modal .ScratchCard__Result i) {
    display: none;
  }

  .ScratchCard__Container:not(.modal .ScratchCard__Container) {
    height: 140px !important;
  }

  .coupon-used {
    height: 126px !important;
    background-size: 50px 56px !important;
  }

  .upcoming-coupon {
    height: 126px !important;
  }

  .scratch-card-inner:not(.modal .scratch-card-inner) {
    height: 126px !important;
  }

  .scratch-card {
    padding: 0.5rem 0 !important;
    margin: 10px 0 !important;
  }
  .liquidity-currency-model {
    max-height: 100vh !important;
  }
}

@media screen and (max-width: 576px) {
  .mob_order_1 {
    order: 1 !important;
  }
  .prosal_img img {
    top: 62px !important;
    position: absolute;
    right: 6px !important;
    width: 101px !important;
  }

  .mob_order_2 {
    order: 2 !important;
  }

  .mob_display {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2a2828;
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 576px) {
  .ana-tb-item .ana-tb-col:first-child {
    padding-left: 1.5rem;
  }

  .ana-tb-item .ana-tb-col:last-child {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 642px) and (max-width: 767px) {
  #carouselLaunchpad .carousel-indicators {
    bottom: 85% !important;
    right: -46px;
    justify-content: end;
  }
}

@media screen and (max-width: 640px) {
  #carouselLaunchpad .carousel-indicators {
    bottom: 85% !important;
    left: 64% !important;
    justify-content: center;
  }

  .ana-tb-list:not(.ana-tb-list.homepage_table) {
    display: grid;
  }

  .ana-tb-list.colorfull_table {
    display: table !important;
  }

  .left_div,
  .right_div {
    position: unset !important;
    width: unset !important;
    height: unset !important;
    margin-top: 20px;
    clear: both;
  }

  .btn_icon {
    width: 40px;
    height: 40px;
    color: #fff !important;
    cursor: pointer;
    padding: 0px !important;
  }

  .l_btn {
    cursor: pointer;
    padding: 5px !important;
    height: 40px !important;
    padding: 0px 5px !important;
  }
}

@media (min-width: 1540px) {
  .ana-tb-list {
    font-size: 14px;
  }
}

.token-name-text {
  text-transform: uppercase;
  /* font-weight: 800; */
  font-size: 10px;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #fff;
  margin-right: 5px;
}

.sub-text {
  color: #abb1bf;
  font-size: 10px;
  line-height: 1.2;
  /* text-align: right; */
  font-weight: bold;
  letter-spacing: 1px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #58e758;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ee4040;
}

.divider {
  border-top: 1px solid #cccccc47;
  margin: 5px;
}

.hero {
  position: relative;
}

.hero:before {
  color: #969696;
  background: #1a1d1f url(/public/image/hero-bg.jpg);
  background-size: cover;
  padding: 0.5rem !important;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  /* background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png'); */
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.hero-content {
  position: relative;
}

.rewardBackdrop {
  /* background: linear-gradient(0deg, rgba(23, 43, 76, 0.58), rgba(0, 32, 84, 0.65)), url(/src/map-2.png) repeat; */
  background: linear-gradient(0deg, #1a1d1f52, #0d0d0e4a), url(/public/image/map-2.png) repeat;
  height: 220px;
  margin-bottom: 6rem;
  padding: 1rem 0;
  /* opacity: .5; */
  /* background-size: auto; */
  z-index: 0;
  background-position: top center;
}

.tokenBackdrop {
  background: linear-gradient(0deg, #1a1d1f52, #0d0d0e4a), url(/public/image/map-2.png) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 60px 0 60px;
}

.token-bg {
  background: linear-gradient(0deg, #1a1d1f52, #0d0d0e4a), url(/public/image/token-bg.png) no-repeat;
  background-size: contain;
  height: 100vh;
  background-position: right;
  background-blend-mode: color;
}

.page-wrapper {
  padding: 2rem 0;
}

.page-wrappers {
  padding: 4rem 0;
}

.form-control {
  background: #f7f7f7;
  color: #000000;
  border: 1px solid #36363629;
  font-size: 13px;
  padding: 0.575rem 0.75rem;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control:disabled,
.form-control[readonly] {
  /* background: #272a2c; */
}

.input-group-text {
  background-color: #272a2c;
  border-color: #272a2c;
  color: #5b6267;
  font-size: 14px;
}

.text-nowrap {
  white-space: nowrap !important;
}

.page-title {
  padding: 15px 0;
}

.custom-btn {
  border: 1px solid #0b4462;
  outline: none;
  background-image: linear-gradient(to right, #1e5166, #0b4462, #03375c, #0c2954, #181a49);
  color: rgb(171 196 255/1) !important;
}

.card-icon {
  color: #525252;
  line-height: 50px;
  margin-left: 15px;
  opacity: 0.2;
  position: absolute;
  right: -5px;
  text-align: center;
  bottom: 30px;
}

.card-icon-large {
  font-size: 70px;
  height: 50px;
  /* text-shadow: 3px 7px rgba(0, 0, 0, .3); */
  width: 110px;
}

.card-title {
  color: #85ddcf;
  font-size: 1.3rem;
}

.card-content {
  padding: 20px;
}

.card-content p {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
}

.cardDiv {
  position: relative;
  /* margin-bottom: 20px; */
}

.container-search {
  position: absolute;
  top: 7%;
  right: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-se {
  position: absolute;
  top: 35%;
  right: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-box-input,
.search-box-btn {
  border: none;
  background: none;
  outline: none;
}

button {
  cursor: pointer;
}

.search-box {
  display: flex;
  background: #08090a;
  border-radius: 4em;
}

.search-box-input {
  width: 0px;
  font-size: 1em;
  color: #fff;
  transition: 0.5s;
}

.search-box-btn {
  display: flex;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  background: transparent;
  transition: 0.3s;
}

.search-box-icon {
  margin: auto;
  color: black;
}

.search-box-input::-webkit-input-placeholder {
  color: white;
  opacity: 0.7;
}

.search-box-input::placeholder {
  color: white;
  opacity: 0.7;
}

.search-box .search-box-input {
  padding-left: 2em;
  padding-right: 1em;
  width: 250px;
}

.search-banner .search-box-input {
  width: 0;

  padding: 0px !important;
}

.search-banner .container-search {
  top: 20%;
}

.search-banner .search-box-input::-webkit-input-placeholder {
  color: transparent;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.search-banner .search-box-input::placeholder {
  color: transparent;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.search-box-btn:active {
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}

.accordion-button-mine.collapsed::after {
  background-image: url('') !important;
  content: '+';
  font-size: 30px;
  position: relative;
  top: -10px;
  right: -7px;
}

.accordion-button-mine:not(.collapsed)::after {
  background-image: url('') !important;
  content: '-';
  font-size: 30px;
  position: relative;
  top: -10px;
  right: -7px;
}

.accordion-button-mine:not(.collapsed)::after {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.accordion-button-mine.collapsed::after {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

/* .accordion-button:not(.collapsed)::after {
      background-image: url("../image/expand-arrow.png") !important;
     
  } */

/* 
  .rewards_wrapper {
      margin-top: -200px;
  } */

/* ======Bootm Navigation for 768px ========== */

.mobile-bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* will-change: transform;
               transform: translateZ(0); */
  /* display: flex; */
  height: 50px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #12091e;
  align-content: center;
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.mobile-bottom-nav ul {
  padding-left: 0;
}

.mobile-bottom-nav li {
  list-style-type: none;
}

.menu-list {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: #ccc;
}

.menu-link.is-active {
  position: relative;
  color: #f44336;
  transition: all 0.35s ease;
}

.menu-icon {
  font-size: 0.95rem;
  line-height: 1;
  color: inherit;
}

.menu-name {
  display: none;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  visibility: hidden;
  text-transform: capitalize;
}

.bg-header {
  background-color: #ffffff;
  padding: 0.3px;
  border-bottom: 1px solid #ff000045;
}

/* ======Trade Table status ========== */

.red-bg-80,
.red-bg-60,
.red-bg-40,
.red-bg-20,
.red-bg-10,
.red-bg-8,
.red-bg-5,
.red-bg,
.green-bg-80,
.green-bg-60,
.green-bg-40,
.green-bg-20,
.green-bg-10,
.green-bg-8,
.green-bg-5,
.green-bg {
  position: relative;
}

.red-bg-80:after,
.red-bg-60:after,
.red-bg-40:after,
.red-bg-20:after,
.red-bg-10:after,
.red-bg-8:after,
.red-bg-5:after,
.red-bg:after {
  position: absolute;
  content: '';
  background: rgba(250, 78, 79, 0.14);
  right: 0;
  top: 0;
  width: 280px;
  bottom: 0;
  /* height: 35px; */
}

.green-bg-80:after,
.green-bg-60:after,
.green-bg-40:after,
.green-bg-20:after,
.green-bg-10:after,
.green-bg-8:after,
.green-bg-5:after,
.green-bg:after {
  position: absolute;
  content: '';
  background: rgba(0, 182, 111, 0.14);
  right: 0;
  top: 0;
  width: 280px;
  bottom: 0;
  /* height: 35px; */
}

.green-percent {
  position: absolute;
  background: rgba(0, 182, 111, 0.14);
  right: 0;
  top: 0;
  /* width: 0px; */
  bottom: 0;
  /* height: 35px; */
}

.red-percent {
  position: absolute;
  background: rgba(250, 78, 79, 0.14);
  right: 0;
  top: 0;
  width: 0px;
  bottom: 0;
  /* height: 35px; */
}

.red-bg:after,
.green-bg:after {
  width: auto !important;
}

.red-bg-5:after,
.green-bg-5:after {
  width: 5% !important;
}

.red-bg-10:after,
.green-bg-10:after {
  width: 10% !important;
}

.red-bg-20:after,
.green-bg-20:after {
  width: 20% !important;
}

.red-bg-40:after,
.green-bg-40:after {
  width: 40% !important;
}

.red-bg-60:after,
.green-bg-60:after {
  width: 60% !important;
}

.red-bg-80:after,
.green-bg-80:after {
  width: 80% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background-color: #0c0a26;
  color: #fff;
}

.exchange-pairs {
  border-radius: 3px;
  background-color: #272a2e;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  color: #f5f5f7;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* .exchange-pairs .input-group, */

.exchange-pairs .farm-table-logo {
  border-radius: 8px;
  margin-right: 3px;
}

.exchange-pairs .price-text {
  font-weight: 700;
  color: #ccc;
  text-align: right;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;
}

.exchange-pairs .form-control,
.exchange-pairs .input-group-prepend span,
.exchange-pairs .input-group-append span {
  padding: 0 0 0 28px;
  background-color: #272a2c;
  font-size: 15px;
  color: #fff;
  border: solid 1px #ffffff10;
}

.exchange-pairs .input-group-prepend .input-group-text {
  font-size: 13px;
  padding: 10px;
  width: 55px;
  border-right: 1px solid rgb(24, 25, 78);
  margin-right: 1px;
}

.exchange-pairs .input-group-append .input-group-text {
  font-size: 13px;
  padding: 10px;
  width: 55px;
}

.exchange-pairs input {
  border-color: #71747c;
  /* border-left: 0; */
  font-weight: 300;
}

.exchange-pairs input:focus {
  box-shadow: none;
  /* border-color: transparent; */
}

.fs-12 {
  font-size: 12px;
}

.card-head {
  font-size: 12px;
  padding: 5px 10px;
  /* text-transform: uppercase; */
  margin-bottom: 0;
  white-space: nowrap;
  color: #979797;
}

.form-control-searchicon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1.775rem;
  height: 1.775rem;
  line-height: 2.175rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.content_center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* text-align: center; */
  width: 100%;
  height: 100%;
  color: #6c757d;
}
.dao_banner .react-multi-carousel-item {
  margin-bottom: 25px;
}

.dao_banner .react-multi-carousel-dot button {
  border-width: 0px;
  background-color: rgb(255 245 245 / 30%);
}
.dao_banner .react-multi-carousel-dot--active button {
  background-color: #fff !important;
}
/* .dao_banner > div:first-child {
    height: 60px;
    background-color: red;
  }
  .dao_banner  > div:first-child h3 {
    line-height: 60px;
  } */
.to_make_vcenter {
  margin-top: 15px;
}

.react-multiple-carousel__arrow {
  background: #85ddcf !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

/* .react-multi-carousel-list {
    padding: 30px 0;
  } */

/* --------------- Table Style Start -------------------- */

.table {
  margin-bottom: 0;
}

.table th {
  padding: 0.2rem 0.5rem;
}

.table td {
  font-size: 12px;
  color: #fff;
  border-color: transparent;
  padding: 0.2rem 0.5rem;
}

.popover-heading {
  color: #9096a3;
  font-weight: 600;
  font-size: 10px;
  padding: 0 8px;
}

.tag-label {
  border: 1px solid #23aa82;
  padding: 3px 8px;
  color: #23aa82;
}

thead.table-head tr th > h6,
thead.table-head tr th {
  /* height: 20px; */
  color: #9096a3;
  font-weight: 600;
  font-size: 10px;
  /* line-height: 1.2; */
  border-top: 1px solid transparent;
  /* padding: 4px 14px; */
  /* text-align: center; */
  text-transform: uppercase;
  margin-bottom: 0;
}

.thead.table-head tr th {
  padding: 20px;
}

.table > :not(:first-child) {
  border-top: none;
}

.exchange-pairs tbody tr td i.fa.fa-arrow-down {
  color: rgb(238, 64, 64);
}

.exchange-pairs tbody tr td i.fa.fa-arrow-up {
  color: rgb(88, 231, 88);
}

.exchange-pairs thead tr th,
.exchange-pairs tbody tr td {
  cursor: pointer;
  font-weight: 400;
}

.exchange-pairs .showing_content {
  position: relative;
  overflow: hidden;
  margin: auto;
}

/* .exchange-pairs {
      border-top: 1px solid transparent;
  } */

/* 
  .exchange-pairs .hidden_content {
      display: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 23%, #222231 31%, hwb(231deg 17% 81%));
      position: absolute;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
  }
  
  .exchange-pairs tr.showing_content:hover .hidden_content {
      display: block;
      width: 100%;
      top: 0;
      bottom: 0;
  } */

.exchange-pairs tr.selected {
  background-color: #171722;
  color: #fff !important;
  border-left: 3px solid #0d6efd;
}

.exchange-pairs tr.order_sell {
  border-left: 3px solid #780f0f;
}

.exchange-pairs tr.order_sell.completed {
  background-color: #ff00002e;
}

.exchange-pairs tr.order_buy.completed {
  background-color: #25ff752b;
}

.exchange-pairs tr.order_buy {
  border-left: 3px solid green;
}

.exchange-pairs tr.sell.selected {
  background-color: #343a40;
  color: #fff !important;
  border-left: 3px solid red;
}

.sidebar-overflow tr:hover:not(.order_sell, .order_buy, .selected) {
  background-color: #242424;
  color: #fff !important;
}

.exchange-pairs table,
.exchange-pairs tr {
  width: 100%;
  table-layout: fixed;
  color: #fff;
  border-color: #57575700;
}

.exchange-pairs tr {
  padding: 5px;
  float: left;
  clear: both;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: 1px solid #383839;
}

/* .exchange-pairs tbody td:nth-child(2) {
      width: 85%;
  } */

#exchange-wrapper .thead th tr {
  border-top: 1px solid transparent !important;
}

table tbody > tr.fadeInRed {
  -webkit-animation: fadeInRed 1s ease-out 1;
          animation: fadeInRed 1s ease-out 1;
  background: linear-gradient(to right, transparent 80.86%, rgba(250, 78, 79, 0.14), rgba(250, 78, 79, 0.14) 80.86%);
}

table tbody > tr.fadeInGreen {
  -webkit-animation: fadeInGreen 1s ease-out 1;
          animation: fadeInGreen 1s ease-out 1;
  background-color: #1e3732;
}

.trade-table {
  margin-bottom: 0;
  color: #fff;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  position: relative;
  table-layout: fixed;
  font-weight: 600;
  text-align: center;
}

.trade-table-left {
  width: 50%;
  left: 0;
  float: left;
}

.trade-table-right {
  float: right;
  width: 50%;
}

.trade-table-left tr td:first-child {
  color: #66c37b !important;
}

.trade-table-right tr td:first-child {
  color: #f6685e !important;
}

/* .order-history tbody>tr>td,  */
.trade-history table {
  width: 100% !important;
  table-layout: fixed !important;
  /* color: #fff; */
  /* border-color: #57575700; */
}

.trade-history tbody > tr > td,
.order-history tbody > tr > td {
  /* text-align: center; */
  white-space: nowrap;
}

.order-history thead tr,
.trade-history thead tr {
  border-bottom: 1px solid transparent;
}

.order-history tbody:not(.divider-tbody) > tr {
  border-bottom: 1px solid #191919 !important;
}

.order-history tr > th {
  padding-left: 10px;
  padding-right: 10px;
}

.order-history tbody > tr > td {
  border: transparent;
  font-size: 11px;
  font-style: normal;
  /* font-weight: 400; */
  /* line-height: 18px; */
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0em;
  padding-bottom: 2px;
}

.truncate_long_txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.red,
.price-down {
  color: #fa4e4f;
  font-size: 22px;
  line-height: 1.2;
}

.price-normal {
  color: #fff;
  font-size: 22px;
  line-height: 1.2;
}

.green,
.price-up {
  color: #00b66f;
  font-size: 22px;
  line-height: 1.2;
}

.green-text,
.buy_txt {
  color: #00b66f !important;
}

.red-text,
.sell_txt {
  color: #fa4e4f !important;
}

/* ---------------Table Style End-------------------- */

/* ============ Nav Pills style ============*/

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-bottom: 2px solid #65e6d4 !important;
  color: #fff !important;
  background-color: transparent !important;
}

.nav-pills .nav-link.selltab.active {
  border-bottom: 2px solid #dc3545;
}

.nav-pills li.nav-item {
  border-bottom: 0 !important;
}

.nav-pills .nav-link {
  color: #979797 !important;
  /* background-color: #2a2a37!important; */
  border-radius: 0 !important;
  /* border-top: 3px solid transparent;
      border-right: 1px solid #ffffff12;
      border-bottom: 1PX solid #ffffff12; */
  padding: 0.5rem 0.6rem;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.order-book .nav-pills .nav-link {
  padding: 0.2rem 0.8rem;
  text-transform: uppercase;
}

.overflow-wrapper-buysell .dropdown-menu {
  background-color: #1a1d1f !important;
  border-top: 1px solid #ffffff12;
}

.overflow-wrapper-buysell .dropdown-item {
  color: #fefefe;
}

.overflow-wrapper-buysell .dropdown-item:focus,
.overflow-wrapper-buysell .dropdown-item:hover {
  color: #fff;
  background-color: #161d2b;
}

.sidebar-overflow {
  max-height: 400px;
  min-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-wrapper-orderbook {
  height: 177px;
  /* min-height: 100px; */
  overflow-y: auto;
}

.overflow-wrapper {
  height: 413px;
  min-height: 312px;
  overflow-y: auto;
}

.overflow-wrapper-trade {
  height: 113px;
  /* min-height: 200px; */
  overflow-y: auto;
}

.overflow-wrapper-currency-details {
  height: 277px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-269 {
  height: 269px;
  overflow-y: auto;
}

.overflow-wrapper-buysell {
  height: 409px;
  min-height: 313px;
  overflow-y: auto;
  overflow-x: hidden;
}

.login-overlay {
  position: absolute;
  height: 92%;
  width: 100%;
  background-color: #1a1d1f;
  z-index: 1;
  margin-top: 36px;
  /* top: 6%; */
}

.login-overlay-bg {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.footer {
  padding: 0.6rem 0;
  background-color: #000;
  bottom: 0;
  width: 100%;
  /* border-top: 2px solid #045e70;
      margin-top: 1rem; */
}

.footer p {
  color: #ccc;
}

.panel {
  display: none;
  overflow: hidden;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.mainSwapContainer {
  /* padding-top: 10px !important; */
  padding-bottom: 10rem;
}

.farm-table-logo {
  background-color: #045e70;
  border-radius: 8px;
  width: 20px;
}

.main {
  width: 100%;
  overflow: hidden;
  padding: 0 0px;
}

.main:after {
  content: '';
  display: block;
  clear: both;
}

.exchange .card {
  /* background: #1a1d1f; */
}

/* ========== card Design for Forms ============ */

.cardBg {
  padding: 1px;
  border-radius: 20px !important;
  /* background: #fff !important; */
  /* background-image: linear-gradient( var(--gradient-rotate, 246deg), #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%) !important; */
}

/* 
  .cardBg:before {
      content: "";
      position: absolute;
      top: -15px;
      right: -35px;
      bottom: -15px;
      left: -35px;
      z-index: -1;
      pointer-events: none;
      background: radial-gradient( closest-side at 66.7%, #e300ff 100%, transparent 0), radial-gradient(closest-side at 33.3%, #39d0d8 100%, transparent 0);
      filter: blur(90px);
      opacity: 0.45;
  } */

/* .cardBg:before {
      content: "";
      position: absolute;
      top: -15px;
      right: -35px;
      bottom: -15px;
      left: -35px;
      z-index: -1;
      pointer-events: none;
      background: radial-gradient( closest-side at 66.7%, #e300ff 100%, transparent 0), radial-gradient(closest-side at 33.3%, #39d0d8 100%, transparent 0);
      filter: blur(90px);
      opacity: 0.45;
  } */

.cardBgInner {
  /* background-color: #1a1d1f; */
  padding: 20px 24px 20px;
  border-radius: 20px !important;
  color: #fff;
  border: 1px solid #7d6f964a;
}

.cardBg label {
  font-weight: normal;
  color: rgb(27, 27, 28) !important;
  font-size: 14px;
  padding: 5px 0;
}

.divBackdrop {
  background-color: transparent;
  padding: 10px 0;
}

.cardBg select {
  background-color: #ffffff;
  border: 1px solid #31314742;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
  color: rgb(14 14 14 / 42%) !important;
}

.cardBg input:not([type='radio']) {
  border-radius: 5px;
  border: 1px solid #31314740 !important;
  /* outline: none; */
  background: #ffffff;
  color: #000000bf;
  padding-left: 10px;
  width: 100%;
  line-height: 32px;
}

.form-select:focus {
  border: 0;
  box-shadow: none;
}

.cardBg label.radio-inline {
  margin: 0 3px !important;
}

.cardBg input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  margin-right: 3px;
  margin-top: 3px;
  background-clip: content-box;
  border: 2px solid #1c5e97;
  background-color: #0c0a26;
  border-radius: 50%;
}

.cardBg input[type='radio']:checked {
  background-color: #006cf7 !important;
}

/* input::placeholder {
      color: #fdfdfd4d;
      text-transform: capitalize;
  } */

.input-checkbox {
  width: 20px !important;
  height: 20px !important;
  border-radius: 8px !important;
}

.input-checkbox-label {
  position: relative;
  top: -5px;
  left: 10px;
  font-size: 16px;
}

/* .input-radio{
      width:25px;
      height:25px;
  } */

.radio-inline {
  position: relative;
  top: -7px;
  margin: 0 7px !important;
  cursor: pointer;
  font-size: 14px !important;
}

.radio-span {
  margin-right: 25px;
}

.tokenlist {
  width: 90%;
}

.tokenDetailscard {
  background-color: transparent !important;
  /* border: 3px solid #0a628d; */
  margin: 10px 0;
  /* padding: 10px; */
  border-radius: 8px;
}

/* 
  .tokenlistSubmit button {
      width: 300px;
  } */

.tokenlistSubmit button:hover {
  opacity: 0.8;
}

.tokenlist-search input {
  width: 80% !important;
  height: 38px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.tokenlist-search button {
  width: 20%;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border: 1px solid #0a628d;
}

.tokenlist-search button:hover {
  opacity: 0.8;
}

.tokenlist-info {
  color: #fff;
}

.tokenlist-info span {
  color: rgb(82 101 146);
}

.cardBg .badge {
  margin-right: 10px;
}

.cardBg .hint {
  font-size: 12px;
  color: #7171719c;
  margin: 5px 0;
  font-size: 12px;
  font-weight: normal;
}

.swap {
  padding: 1px;
  width: 453px;
  margin-top: 140px !important;
  border-radius: 20px !important;
  /* background: #fff !important; */
  background-image: linear-gradient(246deg, #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
  background-image: linear-gradient(var(--gradient-rotate, 246deg), #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
  /* border:3px solid skyblue !important */
}

.swap:before {
  content: '';
  position: absolute;
  top: -15px;
  right: -35px;
  bottom: -15px;
  left: -35px;
  z-index: -1;
  pointer-events: none;
  background: radial-gradient(closest-side at 66.7%, #e300ff 100%, transparent 0),
    radial-gradient(closest-side at 33.3%, #39d0d8 100%, transparent 0);
  -webkit-filter: blur(90px);
          filter: blur(90px);
  opacity: 0.45;
}

.innerSwap {
  background-image: linear-gradient(140.14deg, rgba(0, 182, 191, 0.15), rgba(27, 22, 89, 0.1) 86.61%),
    linear-gradient(321.82deg, #18134d, #1b1659);
  padding: 20px 24px 20px;
  border-radius: 20px !important;
}

.swap select {
  min-height: 45px !important;
  border-radius: 10px;
  border: 2px solid #d0d5db;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
}

.swap select:focus {
  /* border: 1px solid #c4c1c1; */
  border: none;
  outline: 1px solid #c4c1c1;
}

.swap option {
  font-size: 18px;
}

.swap option:hover {
  background-color: #c4c1c1 !important;
}

.swapButton {
  background: rgb(22, 58, 108);
  background: linear-gradient(90deg, rgba(22, 58, 108, 1) 0%, rgba(26, 29, 85, 1) 100%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 1px solid #58f3cd; */
  border: none;
  margin: 10px 0;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.swapButton:hover {
  background-color: #544258;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  background: linear-gradient(0deg, rgba(22, 58, 108, 1) 90%, rgba(26, 29, 85, 1) 100%);
}

.swapInput {
  min-height: 30px;
  border-radius: 5px;
  /* border: 1px solid #58f3cd; */
}

.swapInput input {
  font-size: 16px;
  min-height: 30px !important;
  border: none;
  outline: none;
  background: #120e3d;
  color: rgba(171, 196, 255, 0.5);
  padding-left: 10px;
}

.swapInput button {
  height: 30px;
  border: none;
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #120e3d;
  padding: 0px 8px;
  color: rgb(171 196 255/1) !important;
}

.youBtn {
  font-size: 16px;
  border-right: 0.7px solid rgba(171, 196, 255, 0.5) !important;
}

.youBtn11 {
  font-size: 10px !important;
  font-size: 10px !important;
  background: #5a637c87 !important;
  border-radius: 5px;
  margin-top: 6px;
  height: 18px !important;
  padding-left: 2px !important;
  padding-right: 4px !important;
  color: rgba(171, 196, 255, 0.5) !important;
}

.youIcon {
  font-size: 35px;
}

.routeBtn {
  background: linear-gradient(90deg, rgba(22, 58, 108, 1) 0%, rgba(26, 29, 85, 1) 100%);
  border-radius: 15px;
  border: none;
  padding: 2px 0px 0px 2px;
}

.routeBtn div {
  line-height: 50px;
  font-size: 17px;
  font-weight: 400;
  outline: none;
  border-radius: 15px;
  color: #3bd0d8;
}

.routeBtn:hover {
  background: linear-gradient(0deg, rgba(22, 58, 108, 1) 90%, rgba(26, 29, 85, 1) 100%);
}

.amountSelector {
  border-radius: 4px !important;
  background-color: #d0d5db !important;
  margin: 0px !important;
}

.networkButton {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background: rgb(22, 58, 108);
  background: linear-gradient(90deg, rgba(22, 58, 108, 1) 0%, rgba(26, 29, 85, 1) 100%);
  color: #3bd0d8;
  /* border: 1px solid #58f3cd; */
  outline: none;
  border: none;
  font-size: 17px;
}

.networkButton:hover {
  background: linear-gradient(0deg, rgba(22, 58, 108, 1) 90%, rgba(26, 29, 85, 1) 100%);
}

.modalUl {
  padding: 10px;
  overflow-y: auto;
  max-height: 500px;
  /* border: 1px solid #c4c1c1; */
}

.modalUl li {
  border: 1px solid #045e70;
  border-radius: 10px;
  padding: 15px;
  margin: 5px;
  list-style: none;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
}

.modalTitle {
  color: #fff;
}

.modalUl li:hover {
  background-color: #085597;
}

.token-dropdown {
  background-color: #15245a !important;
  color: rgb(171 196 255/1) !important;
  --bs-dropdown-min-width: 9.4rem !important;
  margin-top: 1px !important;
}

.token-dropdown li {
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}

.token-dropdown li:hover {
  background-color: #0c0a26;
  color: #fff;
}

.transfer {
  font-weight: 600;
  color: rgba(171, 196, 255, 0.5);
  font-size: 13px;
}

.recieves {
  background-color: #130f3f;
  padding: 24px 12px;
  border-radius: 10px;
}

.colorT {
  font-size: 16px;
  font-weight: 600;
}

.swap small {
  font-size: 11px;
  color: rgba(171, 196, 255, 0.6);
}

.modal-header .btn-close {
  /* background-image: url("../../public/image/times.png") !important; */
  background-repeat: no-repeat !important;
  background-position: center/1em auto;
  background-size: cover !important;
  width: 20px;
  height: 20px;
}

/* .red {
      color: rgb(238, 64, 64);
  }
  
  .green {
      color: rgb(88, 231, 88);
  } */

.switch-label {
  position: relative;
  top: -7px;
  left: 8px;
}

.input-checkbox {
  width: 15px !important;
  height: 15px !important;
  border-radius: 8px !important;
}

.input-checkbox-label {
  position: relative;
  top: -5px;
  left: 10px;
  font-size: 16px;
}

.taxHeading,
.mainFormHead {
  font-weight: bold;
  color: var(--bs-green) !important;
  font-size: 14px;
  padding: 4px 0px;
  border-bottom: 1px solid #eeeeee14;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.form-check-input-custom {
  color: transparent !important;
  background: none !important;
}

.swal2-confirm {
  background-color: #288a7f !important;
}

/* ***************swap************************* */

a.accordion-button:hover {
  color: #bababa !important;
}

.accordion-button {
  background-color: #1b2c42 !important;

  color: #bababa;
}

.accordion-item {
  background-color: #181b1c00;
  color: #000;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 25px;
}

.seedx_dao .accordion-item {
  background-color: #181b1c;
  color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #1b2c42 !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.accordion-item .accordion-button.collapsed {
  border-radius: 10px;
}

.accordion-item:first-of-type {
  border-radius: 10px;
}

.accordion-item:last-of-type {
  border-radius: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.accordion-item:first-of-type .accordion-button:not(.collapsed) {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.header-label {
  font-size: 13px;
  color: #9fa0a0;
  margin-bottom: 10px;
}

.grey_text {
  color: #979797 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-color {
  color: #adadad;
}

a:hover:not(.pointer) {
  color: #8e99c5;
}

.card-border {
  border: 1px solid #4a4b4c;
  border-radius: 1.25rem;
}

.card-border2 {
  border: 1px solid #32363a;
  border-radius: 1.25rem;
}

.custom_border {
  border-right: 1px solid #32363a;
  border-radius: 0.15rem;
}
.accordion-body {
  border: 1px solid #1b2c42 !important;
  position: relative;
  z-index: 1;
}
.form-label {
  color: #fff !important;
}

/* ............for Primary Action buttons (eg: submit, Login, Signup) .................. */

.btn-submit {
  background: black;
  /* background: linear-gradient(270deg, #ff044a, #c61ca3); */
  border-radius: 4px;
  text-align: center;
  padding: 10px 10px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* border-radius: 1.2rem; */
  white-space: nowrap;
  border-color: #12121282;
}

.btn-submit:hover {
  background-position: right center;
  color: #000;
  text-decoration: none;
  border-color: #12121282;
}
.btn:disabled {
  background-color: gray !important;
}

/* ............for secondary action buttons .................. */

.btn_customised {
  background: linear-gradient(270deg, #ff044a, #c61ca3);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  white-space: nowrap;
  border-color: #c61ca3;
}

.btn_customised:hover {
  background-position: right center;
  color: #fff !important;
  text-decoration: none;
}

.btn-grad2,
.btn-grad {
  background: black;
  /* background: linear-gradient(270deg, #ff044a, #c61ca3); */
  border-radius: 4px;
  white-space: nowrap;
  padding: 3px 12px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-color: #12121282;
  /* box-shadow: 0 0 6px #739aad; */
  /* border-radius: 1.2rem; */
}

.btn-grad2:hover,
.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff !important;
  text-decoration: none;
}

.btn-grad3 {
  background: black;
  /* background: linear-gradient(270deg, #f705d9, #2662b5); */
  border-radius: 4px;
  white-space: nowrap;
  padding: 3px 12px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
}

.btn-grad3:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-grad2-outline,
.btn-grad-outline {
  border: 1px solid #36b369;
  border-radius: 4px;
  white-space: nowrap;
  padding: 3px 12px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #ffffff;
  /* border-radius: 1.2rem; */
}

.btn-grad2-outline:hover,
.btn-grad-outline:hover {
  box-shadow: 0 0 6px #7c699e;
  color: #65e6d4;
  text-decoration: none;
}

.btn-outline-pink {
  border: 1px solid #601e58;
  border-radius: 4px;
  white-space: nowrap;
  padding: 3px 12px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #c835b6;
  /* border-radius: 1.2rem; */
}

.btn-outline-pink:hover {
  box-shadow: 0 0 6px #f705d9;
  color: #f705d9;
  text-decoration: none;
}

.btn-outline-gray {
  border: 1px solid #5a5a5a;
  border-radius: 4px;
  white-space: nowrap;
  padding: 3px 12px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #bbb;
  /* border-radius: 1.2rem; */
}

.btn-outline-gray:hover {
  box-shadow: 0 0 6px #5a5a5a;
  color: #fff;
  text-decoration: none;
}

/* ............for inut gropu button css start.................. */

input[type='date']::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
  margin: 0px;
  opacity: 0.5;
}

.form_input {
  background: transparent;
  border: 0;
  color: #969cbf;
  padding-left: 0;
}

.form_input:focus {
  box-shadow: none;
}

.limitcard .form-control:not(:disabled):focus {
  background: #272a2c !important;
  border-color: transparent !important;
  color: #eee;
  box-shadow: none;
}

.form-control:not(:disabled):focus {
  background: #f7f7f7;
  border-color: #2a55757d;
  color: rgb(0, 0, 0);
  box-shadow: none;
}

.form-control[readonly]:focus {
  /* background: #323638; */
  border-color: #2a547540;
  color: #6c757d;
  box-shadow: none;
}

#nav-quickbuy .form-control:not(:disabled):focus {
  background: transparent !important;
  border-color: transparent !important;
  color: #eee;
  box-shadow: none;
}

select option {
  background: #272a2c;
  color: #ccc;
}

.form_bg {
  background-color: #000;
  padding: 0.8rem;
  margin-bottom: 2rem;
  /* border: 1px solid #373737; */
  border-radius: 1.25rem;
  box-shadow: 1px -1px 13px #1e1b1b;
}

.form_inform {
  border-radius: 10px !important;
  margin-bottom: 10px !important;
}

/* .form_bg:hover {
      border: 1px solid #5e563d;
  } */

/* ............for input group button css end.................. */

.btn-next {
  color: #fff;
  font-size: 1rem;
  text-transform: lowercase;
  font-weight: 400;
}

.btn-next::after {
  content: '»';
  width: 5px;
  font-size: 1.4rem;
  height: 9px;
  position: relative;
  top: -2px;
  margin: 0 0px 0px 5px;
}

.sidebar-overflow .popover_content {
  padding-bottom: 8px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  width: 340px;
  display: none;
  position: fixed;
  background-color: #2a2a37;
  -webkit-transform: translate(50px, -10%);
  transform: translate(50px, -10%);
  border-radius: 3px;
  border: 1px solid #0f111e;
  z-index: 10000;
  left: 13%;
}

.sidebar-overflow tr:hover .popover_content {
  display: block;
}

/************************sideBar***********************/

.sidebar {
  position: relative;
  z-index: 1;
  width: 12%;
  display: inline-block;
  min-height: 100vh;
  background-color: #111a34;
  padding-top: 100px;
  color: #fff;
}

.sidebar ul {
  list-style: none;
  padding: 0px;
}

.sidebar ul li {
  text-align: initial;
  padding: 10px 5px;
  margin: 5px;
}

.side-text {
  font-size: 17px;
  font-weight: 600;
}

.side-text:active {
  color: #84aeb7;
}

.side-text:visited {
  color: #84aeb7;
}

.mainSwapContainer {
  /* margin-left: 12%; */
  width: 88%;
  float: left;
  display: inline-block;
}

/* =========== Social Icons ============== */

.icon-link {
  margin: 5px;
}
.icon-link svg {
  width: 40px !important;
  height: 40px !important;
}

.icon-link.round {
  border-radius: 50%;
}

.icon-link.round-corner {
  border-radius: 5px;
}

.icon-link:hover {
  transition: background-color 150ms ease-in-out;
  color: #fff !important;
}

.icon-link .fab {
  color: #fff;
  line-height: 60px;
  font-size: 25px;
}

.brand-icons {
  background-color: #ddd;
  padding: 10px 0;
}

.poolinfo_accordion .accordion-item:first-of-type .accordion-button:not(.collapsed) {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.poolinfo_accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.poolinfo_accordion .accordion-item .accordion-button.collapsed {
  border-radius: 3px;
}
.poolinfo .accordion-item:last-of-type {
  border-radius: 3px;
}

.verify_token_main {
  padding: 1rem 0;
}

.verify_token_main .verify_token_cards {
  border-right: 1px solid #333;
  padding: 0 1rem;
}

.verify_token_main .verify_token_cards:last-child {
  border-right: 1px solid transparent;
}

/* 
  .verify_token_main div.verify_token_cards {
  border-right: 0;
  } */

@media screen and (max-width: 1600px) {
  .sidebar {
    width: 15%;
  }

  .mainSwapContainer {
    /* margin-left: 15%; */
    width: 85%;
  }
}

@media screen and (max-width: 1300px) {
  .sidebar {
    width: 18%;
  }

  .mainSwapContainer {
    /* margin-left: 18%; */
    width: 82%;
  }
}

.sidenav-button {
  display: none;
}

@media screen and (max-width: 1020px) {
  /* .nav {
          padding: 10px 10px !important;
      } */
  .connectWallet {
    width: 145px;
  }

  .sidenav-button {
    display: block;
    clear: both;
  }

  .sidebar {
    position: absolute;
    width: 230px;
    transition: left 1s;
  }

  .side-inactive {
    left: -300px;
  }

  .side-active {
    left: 0 !important;
  }

  .mainSwapContainer {
    margin-left: 0;
    width: 100%;
    display: block;
  }

  .fimob {
    order: 1 !important;
  }

  /* .cardBg input[type=radio] {
          margin-right: 3px;
          margin-top: 3px;
          width: 20px !important;
          height: 20px !important;
      } */
}

@media screen and (min-width: 991px) {
  .custom-padding {
    padding: 0 !important;
  }

  .mobile-view-tbcontent {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .currency_carousel .react-multi-carousel-dot-list {
    display: none !important;
  }

  .mob_accordion_heading {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  /* .verify_token_cards {
      border-right: none !important;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      margin-bottom: 10px;
    } */
  /* .verify_token_cards:last-child {
      border-bottom:none;
    } */

  .hpadding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .profile__stats {
    display: grid !important;
  }
  .profile__stat {
    border-right: none !important;
    border-bottom: 1px solid #333;
  }
  .profile__stat:last-child {
    border-bottom: none;
  }

  .stats-info {
    margin-top: 6rem;
  }

  .on_mob_order_1 {
    order: 1 !important;
  }
  .on_mob_order_2 {
    order: 2 !important;
  }
  .img-container {
    padding: 30px;
    right: 0;
    left: 0;
    position: relative;
    margin: auto;
  }

  /* .homepage_table .ana-tb-col:nth-child(2) {
          width: 170px;
          text-align: center !important;
      } */

  .show_on_mobile {
    display: inline-block;
  }

  .hide_on_mobile {
    display: none !important;
  }

  .scratch-card-inner canvas {
    height: 100% !important;
  }

  .accordion-button::after {
    height: 0.85rem;
    background-size: 1rem;
    width: 1.15rem !important;
  }

  /* .mob-padding {
              padding: 0!important
          } */
  .mob_p_3 {
    padding: 0.5rem 0.2rem !important;
  }

  .mob_p_2 {
    padding: 0.6rem !important;
  }

  .mob_marg {
    margin-bottom: 0.5rem;
  }
  .mob-marg-0 {
    margin-bottom: 0 !important;
  }

  .width120 {
    width: 70px !important;
  }

  .header-label {
    font-size: 10px;
  }

  .mob-font {
    font-size: 12px !important;
  }
  .faqsection .mob-font {
    font-size: 10px !important;
  }

  .mob_col_hidden {
    display: none;
  }

  .mob_accordion_heading {
    background-color: #22272a;
    padding: 15px 15px;
    border-top: 1px solid #eeeeee14;
  }

  .rewardBackdrop {
    /* height: 100%; */
    background: none;
    margin-bottom: 0;
  }

  .min-height {
    height: unset !important;
  }

  /* .card-content {
      padding: 0;
    } */

  .card-icon-large {
    font-size: 30px;
    height: 20px;
    width: 80px;
  }

  .card-title {
    color: #687180;
    font-size: 1.2rem;
  }

  .sidebar-overflow .nav-pills .nav-link {
    font-size: 14px !important;
  }

  .card-group-section {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .analytice_img img {
    position: unset !important;
    margin: 0 auto 20px;
  }

  /* .verify_token_main {
      display: inherit;
     } */

  .verify_token_main .verify_token_cards {
    border-bottom: 1px solid #333;
    border-right: none;
    padding: 0.5rem;
  }

  .verify_token_main .verify_token_cards:nth-child(5),
  .verify_token_main .verify_token_cards:nth-child(6) {
    border-bottom: 1px solid transparent;
  }

  .profile-layout {
    display: grid !important;
    height: 140px !important;
  }

  .profile__picture {
    position: relative;
    top: 25%;
    /* left: 35%; */
    margin: 0 auto;
    border-radius: 50% !important;
    margin-top: 0 !important;
    /* margin-left: 0 !important; */
  }
  .pad-bot-0 {
    padding-bottom: 0px !important;
  }

  .profile__picture img {
    border-radius: 50%;
  }

  .address {
    text-align: center;
    padding-top: 13px;
  }
  .stats-info {
    margin-top: 9rem;
  }

  .custom-header .nav-link.active,
  .custom-header .show > .nav-link {
    color: #fff;
    border-bottom: transparent !important;
  }

  .exchange-pairs .price-text {
    text-align: center !important;
  }

  .mainSwapContainer {
    justify-content: flex-start !important;
    width: 100%;
  }

  .mainPoolContainer {
    justify-content: flex-start !important;
    width: 111% !important;
  }

  .circle_bg {
    background: linear-gradient(126.6deg, #2db3a042 28.69%, #65e6d442 100%);
    border-radius: 9999px;
    width: 2rem;
    height: 2rem;
    position: relative;
    margin-right: 5px;
  }

  .circle-pointer {
    cursor: pointer;
  }

  .main {
    padding: 0 10px;
  }

  .mobile-bottom-nav {
    display: block;
  }

  /* .swap{
      margin-top: 80px !important;
    } */
  .radio-span {
    margin-right: 10px;
  }

  .mobile_scroll {
    display: grid;
    white-space: nowrap;
    overflow-x: auto;
    width: 200vh;
  }

  .mobile_scroll_main {
    width: 100%;
    overflow-x: auto;
    padding: 20px 24px 20px;
    border-radius: 20px !important;
    color: rgba(196, 214, 255, 0.5);
    border: 1px solid #373737;
  }

  .tokenDetailscard {
    border: 3px solid #0a628d;
    /* padding: 10px; */
  }

  .sidebar-overflow .popover_content {
    left: 40%;
  }
  .tokenBtn-flex {
    flex-direction: column !important;
    grid-gap: 0px !important;
    gap: 0px !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1340px) {
  .img-container {
    position: relative;
    z-index: 1;
    right: -49px !important;
    width: 77%;
  }
}

@media screen and (min-width: 768px) {
  .profile__picture {
    margin-left: 25px;
  }

  .divOne,
  .divTwo,
  .divThree,
  .divFour,
  .divFive {
    display: block !important;
  }

  .sidebar-overflow .nav-pills .nav-link {
    padding: 0.4rem 0.6rem !important;
    font-size: 14px;
  }
}

@media screen and (max-width: 1360px) {
  .sidebar-overflow .nav-pills .nav-link {
    padding: 0.4rem 0.5rem !important;
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .radio-inline {
    font-size: 13px !important;
    top: -5px;
  }

  /* .tokenlistcontainer{
          padding:0px !important
      } */
  .balanceoftoken {
    text-align: center;
  }

  .tokenlist-search input {
    width: 75% !important;
  }

  .tokenlist-search button {
    width: 25%;
  }

  .cardbgInnerResponsive {
    padding: 18px 15px 20px;
  }

  .icon-link {
    margin: 2px;
  }

  .radio-span {
    margin-right: 0;
  }

  .order-book .nav-pills .nav-link {
    padding: 0.2rem 0.6rem;
  }

  .sidebar-overflow .popover_content {
    width: 78%;
    left: 6%;
  }
}

@media screen and (max-width: 390px) {
  .radio-inline {
    font-size: 10px !important;
    top: -5px;
  }

  /* .tokenlistcontainer{
          padding:0px !important
      } */
  .cardbgInnerResponsive {
    padding: 18px 8px 20px;
  }

  .form_bg {
    padding: 1rem 0.8rem;
  }

  /* .btn-grad {
          padding: 0 10px;
      } */
}

@media screen and (max-width: 362px) {
  .radio-inline {
    font-size: 10px !important;
    top: -5px;
  }

  /* .tokenlistcontainer{
          padding:0px !important
      } */
  /* .cardbgInnerResponsive {
          padding: 18px 3px 20px
      } */
  .icon-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon-link svg {
    width: 30px !important;
    height: 30px !important;
  }
}

#content {
  width: 100%;
}

.react-grid-layout {
  background: #eee;
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  background: #cce;
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

li b {
  font-size: 19px;
  line-height: 14px;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.max-min-btn {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
  background-color: #2a60b5;
  padding: 5px;
  border: none;
  color: #fff;
  margin: 2px;
  border-radius: 5px;
}

.circle_bg {
  background: linear-gradient(126.6deg, #2db3a042 28.69%, #65e6d442 100%);
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  position: relative;
  margin-right: 5px;
}

.inside_img {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-transform: translate(4px, 4px);
          transform: translate(4px, 4px);
  border-radius: 50%;
}

.expand_arrow {
  width: 1rem;
  height: 1rem;
}

.vertical_border {
  width: 4px;
  height: 18px;
  margin: 0 7.5px;
}

.pointer {
  cursor: pointer;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.width120 {
  width: 120px;
}

.width166 {
  width: 166px;
}

.mob-font {
  font-weight: 400;
  color: #9fa0a0;
  font-size: 12px;
  font-weight: bold;
}
.mob-font123 {
  color: white !important;
}

.css-1kioka7 {
  color: #fff !important;
}

.css-whebh7-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgba(105, 102, 102, 0.7) !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: #666 !important;
  /* border: 1px solid#666!important; */
}

.text-gray {
  color: #666 !important;
}

.MuiInputAdornment-root {
  color: rgb(165, 160, 160) !important;
}

.MuiInputBase-input {
  color: #fff !important;
}

.css-1pnmrwp-MuiTypography-root {
  color: rgb(255 255 255 / 80%) !important;
}

.liquidity_wrapper select {
  background-color: #272a2c !important;
  border: 1px solid #363636;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  font-size: 14px !important;
  color: rgb(255 255 255 / 42%) !important;
}

.sellbtn_theme {
  background: linear-gradient(270deg, #e84747 0%, #ff5107 100%);
  color: #fff;
  /* margin-left: 20px; */
  float: right;
}

.buybtn_theme {
  background: linear-gradient(270deg, #ff044a, #c61ca3);
  color: #fff;
  /* margin-right: 20px; */
  float: left;
}

.btn_theme1 {
  flex-grow: 1;
  width: 80%;
  float: left;
  color: #fff !important;
  background: linear-gradient(270deg, #ff044a, #c61ca3);
}

.btn_theme2 {
  flex-grow: 1;
  width: 80%;
  float: right;
  color: #fff !important;
  background: linear-gradient(270deg, #e84747 0%, #ff5107 100%);
}

.buysellbtn-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.last_price_wrapper {
  padding: 8px 0px;
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  background-color: #2525258f;
}

.community-checkbox {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.community-label {
  position: relative;
  top: -6px;
  left: 5px;
}

.scratch-card {
  padding: 1rem 0;
  margin: 20px 0;
  position: relative;
}

.static_coupon_card .scratch-card-inner img {
  height: 215px;
  width: 100%;
}

.scratch-card-inner {
  height: 100%;
  width: 100%;
  border-radius: 0.6em;
  box-shadow: 0 1em 2.5em rgba(16, 2, 96, 0.25);
  z-index: 0;
}

.scratch-card-inner canvas {
  width: 100%;
  height: 100%;
  border-radius: 0.6em;
}

.ScratchCard__Result {
  padding: 2rem 1rem;
  /* display: flex;
       align-items:center;
      justify-content:center; */
  width: 100%;
  height: 214px;
  margin: auto;
  border-radius: 10px;
  color: #767676;
  background-color: #dafff9;
  text-align: center;
}

.coupon-used {
  width: 100%;
  height: 213px;
  margin: auto;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  background-color: #242424;
  /* background-image: url("./party-hat.png"); */
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: right bottom;
  background-size: 159px 174px;
  border: 1px dashed #6e6e6e;
}
.coupon-used h3 {
  font-size: 2rem;
}

.availibility {
  bottom: 3rem;
  z-index: 8;
  /* margin: 0 1px; */
  text-align: center;
  background: #303030;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0.4rem 1rem;
}

.page-head {
  color: #fff;
  text-align: center;
}

.page-head::after {
  display: block;
  content: '';
  margin: 10px auto;
  border-bottom: solid 3px #65e6d4;
  width: 120px;
}

.reward-cup,
.grayimg {
  -webkit-filter: grayscale();
          filter: grayscale();
}

.min-height {
  height: 180px;
  margin-bottom: 0 !important;
}

.bg_darken {
  background-color: #253036 !important;
}

.prize_symbol {
  position: absolute;
  line-height: 37px;
  top: -20px;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  width: 40px;
  height: 40px;
  background: #fff;
}

.prize_symbol img {
  position: relative;
  width: 30px;
  height: 30px;
  left: 5px;
  text-align: center;
}

.top_corner {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  background-color: #253036;
  color: #009688;
  padding: 5px;
  width: 30px;
  height: 30px;
  align-items: center;
  text-align: center;
}

.top_corner:after {
  content: '';
  position: absolute;
  left: 3%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 8px solid #253036;
  clear: both;
}

.card_border {
  border: 1px solid #303030;
}

/* span {
      cursor: pointer;
  } */

.number .minus,
.number .plus {
  width: 23px;
  height: 23px;
  background: #444;
  border-radius: 4px;
  line-height: 21px;
  padding: 0px 5px 8px 5px;
  border: 1px solid #2a2a2a;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 22px;
  font-weight: normal;
}

.number input {
  height: 24px;
  width: 40px;
  text-align: center;
  font-size: 12px;
  border: 1px solid #2a2a2a;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  background-color: #343434;
  color: #fff;
}

.custom_modal .modal-dialog {
  max-width: 800px !important;
}

.custom_modal .border-top {
  border-top: 1px solid #424548 !important;
}

.scratch_card_modal .modal-dialog {
  max-width: 600px !important;
}

.ScratchCard__Result h4 {
  font-size: 1.7rem;
  padding: 3.2rem;
  color: #2e848f !important;
}

.vote_btn {
  background: linear-gradient(270deg, #ff044a, #c61ca3);
  padding: 0 5px !important;
  color: #fff;
  font-size: 14px;
}
.vote_btn:hover {
  background: linear-gradient(270deg, #ff044a, #c61ca3);
}
.vote_btn:hover,
.details_btn:hover {
  color: #fff;
}

.details_btn {
  /* background: linear-gradient(270deg, #5d8882 0%, #37665f 100%); */
  background: linear-gradient(270deg, #f705d96e, #2662b5);
  padding: 0 5px !important;
  color: #fff !important;
  font-size: 14px;
  line-height: 23px;
  border: 0;
}

.pool-pills button.nav-link.active,
.pool-pills button.nav-tabs.active {
  background: #009688 !important;
  border-radius: 7px 7px 0 0 !important;
  border-top: 1px solid #3b3d3f !important;
  letter-spacing: 0.066667em !important;
  border-bottom: 3px solid transparent !important;
}

.grid-cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  gap: 0;
  grid-auto-rows: minmax(90px, auto);
}

.grid_columns {
  border: 1px solid #7a7a7a17;
}

.grow {
  flex-grow: 1;
}

.gap-board {
  -webkit-clip-path: inset(1px);
  clip-path: inset(1px);
}

.grid-cols-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
  gap: 0;
  grid-auto-rows: minmax(100px, auto);
}

.circle_icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  color: #fff;
  background-color: #009688;
  margin: 20px auto;
}

.min-height335 {
  min-height: 335px;
}

.social-icons a {
  margin: 10px;
  font-size: 22px;
}

.table_design {
  display: flex;
}

.quill {
  height: 200px !important;
}

.ql-container {
  min-height: 200px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #cccccc40 !important;
}

.ql-container.ql-snow {
  border: 1px solid #cccccc40 !important;
}

.inr-text {
  background-image: linear-gradient(to left, #ffffff, #ffffff, #52a956, #ffffff, #fff, #fff, #52a956);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.skeleton-loader {
  width: auto;
  height: 15px;
  display: block;
  background: linear-gradient(90deg, #f7f4f4, #edf0f7 50%, #7791af 80%), #c8e3ff;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  -webkit-animation: shine 1.5s infinite;
          animation: shine 1.5s infinite;
}

@-webkit-keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.prizepool_table {
  display: table !important;
}

.prizepool_table .ana-tb-list.is-separate {
  border-spacing: 0 10px;
}
.colorfull_table .ana-tb-item:first-child {
  outline: thin solid rgb(106, 106, 209) !important;

  border-radius: 2px;
}

.colorfull_table .ana-tb-item:nth-child(even) {
  outline: thin solid #62440b !important;
  border-radius: 2px;
}

.colorfull_table .ana-tb-item:nth-child(odd) {
  outline: 1px solid #124e12 !important;
  border-radius: 2px;
}

.colorfull_table .ana-tb-item:nth-child(3n + 1) {
  outline: 1px solid #585e14 !important;
  border-radius: 2px;
}

.colorfull_table .ana-tb-item:nth-child(4n + 1) {
  outline: 1px solid #144558 !important;
  border-radius: 2px;
}
.height-30px {
  height: 30px;
}
.drpdwn_connect .dropdown-item {
  font-size: 14px !important;
}

.custom_input_grp .input-group-text {
  border: 1px solid #363636;
  border-left: 0 !important;
}

.custom_input_grp .form-control {
  border-right: 0 !important;
}
.has-border-top-light {
  border-top: 1px solid #383838 !important;
}

.project_cmplted {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-accordion {
  border-radius: 1.2rem;
  padding: 1.2px;
  background-image: linear-gradient(246deg, #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
  background-image: linear-gradient(var(--gradient-rotate, 246deg), #da2eef 7.97%, #2b6aff 49.17%, #39d0d8 92.1%);
}

.bg-accordion-card {
  background-image: linear-gradient(140.14deg, rgba(14, 18, 19, 0.15), rgba(14, 14, 17, 0.1) 86.61%),
    linear-gradient(321.82deg, #13121d, #12111a);
  border-radius: 1.2rem;
  padding: 1.5rem;
}

.seedx_dao .mob-font {
  font-size: 14px;
}
.seedx_dao .col {
  width: 25%;
}
.seedx_dao .col:last-child {
  width: 30px;
}

.prizepool .col {
  width: 25%;
}
.prizepool .col:last-child {
  width: 30px;
}

.img_inside_circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  z-index: 0;
  background: #353636;
}

.img_inside_circle:nth-child(2) {
  margin-left: -5px;
  z-index: 1;
}

.img_inside_circle img {
  position: relative;
  top: -2px;
  -webkit-transform: translateX;
          transform: translateX;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.img_bg {
  width: 30px;
  height: 30px;
  background: #444;
  border-radius: 50%;
  text-align: center;
  z-index: 0;
}
.img_bg1 {
  width: 30px;
  height: 30px;
  background: #1c1b1b;
  border-radius: 50%;
  text-align: center;
  z-index: 0;
}
.img_bg1:nth-child(2) {
  margin-left: -8px;
  z-index: 1;
}
.img_bg:nth-child(2) {
  margin-left: -8px;
  z-index: 1;
}
.img_bg:nth-child(3) {
  margin-left: -8px;
  z-index: 1;
}
.img_bg:nth-child(4) {
  margin-left: -8px;
  z-index: 1;
}

.farm_img_1 {
  position: relative;
  top: 2px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
  text-align: center;
}

.farm_img_2 {
  position: relative;
  top: 2.1px;
  -webkit-transform: translateX(0.4px);
          transform: translateX(0.4px);
  text-align: center;
}

.dao_head_padding {
  padding: 0.5rem 1.25rem 0.2rem;
}

.profile-layout {
  background: #17191b;
  width: 100%;
  display: flex;
  height: 100px;
  position: relative;
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2); */
  /* margin-bottom: 45px; */
  border-radius: 3px;
}

.profile-nav-info {
  /* display: flex; */
  /* flex-direction: row;
    justify-content: flex-start;  */
  padding: 25px 30px;
  /* flex: 1 1; */

  /* /* align-items: flex-end; */
}

.stats_bg {
  margin-bottom: 2rem;
}

.profile__icon {
  flex: none;
  font-size: 1.5em;
  margin-right: 10px;
  padding-top: 3px;
}
.profile__icon--gold {
  color: #eab100;
}
.profile__icon--blue {
  color: #8faae8;
}
.profile__icon--pink {
  color: #ff86af;
}
.profile__key {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #a2a2a2;
}
.profile__picture {
  background: #151e22;
  border-radius: 3px;
  border: 3px solid #666;
  height: 150px;
  /* position: absolute; */
  /* top: -40px; */
  /* top: 10px; */
  /* left: 20px; */
  width: 150px;
  margin-top: 25px;
  /* margin-left: 25px; */
}

.profile__picture img {
  height: 100%;
  width: 100%;
}
.profile__stat {
  border-right: 1px solid rgb(53, 48, 48);
  display: flex;
  flex: 1 1;
  justify-content: center;
  opacity: 1;
  padding: 0 4px 10px;
  /* transform: translateY(5px); */
}
.profile__stat:last-of-type {
  border-right: none;
}

.profile__stats {
  display: flex;
  padding: 10px;
}

.profile__value {
  font-size: 17px;
  font-weight: normal;
  text-align: center;
}

.skelton-placeholder {
  min-height: 20px !important;
}
.skelton-placeholder-image {
  min-height: 145px !important;
}
.skelton-placeholder-social {
  min-height: 180px !important;
  min-width: 100%;
}
.skelton-footer-card {
  min-height: 40px !important;
  min-width: 100%;
}
.cursor-right {
  cursor: pointer;
}
.cursor-none-profile {
  cursor: default;
}
.cursor-none-btn {
  cursor: no-drop !important;
}
.custom-media-card {
  min-height: 250px;
}
.card-description {
  min-height: 288px;
}

.vertically-centered-div {
  padding-top: 7rem;
  position: relative;
  z-index: 999;
}

.sticky-image-wrapper {
  position: fixed;
  bottom: -55px;
  width: 100%;
  z-index: 0;
}

.sticky-image-wrapper img {
  display: table;
  position: relative;
  margin: auto;
}

.custom-footer {
  content: '';
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 385px;
  bottom: -90px;
  background: url('/public/image/line_update.svg') center center no-repeat;
}

.claim-token-banner {
  height: 361px;
  overflow-y: auto;
  max-height: 100%;
}
/* .card-custom-amount{
    padding: 62px 30px;
  }
  @media screen and (max-width: 768px) {
    .card-custom-amount{
      padding: 12px 10px;
    }
  } */
.setHider {
  display: none;
}
.inputbg {
  background: linear-gradient(270deg, #ff044a, #c61ca3) !important;
}
/*
  
  .css-1b1myg3-MuiSlider-thumb::after {
    position: absolute;
    content: "";
    border-radius: 50%; 
    width: 16px !important;
    height: 16px !important;
    top: 15% !important;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    transform: translateX(-50%) rotate(45deg)!important;
    background-color: rgb(30, 35, 41) !important;
    border-radius: 4px !important;
    border:4px solid rgb(203 26 155) !important;
   }
  .css-1tfve6q-MuiSlider-mark {
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: currentColor;
    top: 38% !important;
    -webkit-transform: translate(-1px, -50%);
    -moz-transform: translate(-1px, -50%);
    -ms-transform: translate(-1px, -50%);
   
    
    transform: translateX(-50%) rotate(45deg) !important;
    background-color: rgb(24, 26, 32) !important;
    color: rgb(132, 142, 156) !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 2px !important;
    border: 2px solid rgb(71, 77, 87)!important;
  } 
  .MuiSlider-markActive {
    position: absolute;
    border-radius: 1px;
    background-color: #fff;
    top: 38% !important;
    -webkit-transform: translate(-1px, -50%);
    -moz-transform: translate(-1px, -50%);
    -ms-transform: translate(-1px, -50%);
  
    opacity: 0.8;
    transform: translateX(-50%) rotate(45deg) !important;
    background-color: #060505 !important;
    color: rgb(132, 142, 156) !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 2px;
    border: 2px solid #fff !important;
  } 
  */

.custom_badge {
  color: #adadad;
  font-size: 12px;
  background-color: #3e57b9;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.skUpro_placeholder {
  background-color: #434343 !important;
  border-radius: 4px;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}
.toggle_checkbox .checkbox-label {
  background: linear-gradient(45deg, rgba(140, 0, 255, 0.6) 0%, rgba(2, 242, 114, 0.7) 100%) !important;
  width: 50px;
  height: 25px;
  border-radius: 50px;
  position: relative;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle_checkbox .checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 27px;
  top: 1px;
  border-radius: 50%;
  border: 1px solid rgba(140, 0, 255, 0.6);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.toggle_checkbox .checkbox:checked + .checkbox-label .ball {
  -webkit-transform: translateX(-27px);
          transform: translateX(-27px);
}

.toggle_checkbox .fa-rupee-sign {
  color: #fff;
}

.toggle_checkbox .fa-dollar-sign {
  color: #fff;
}
.btn-participants {
  background: linear-gradient(270deg, #f705d9, #2662b5);
  border: 0.4px solid #4e6d98;
}

.btn-participants:hover {
  background: linear-gradient(270deg, #fb00de, #2669c5);
  border: 0.4px solid #367cdd;
}

.liquidity-model {
  padding: 10px 10px;
  background-color: #272a2c !important;
  border: 1px solid #363636;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px !important;
  color: rgb(255 255 255 / 42%) !important;
}
/* .modal_liquidity_divs .add-liquidity-popup{
    color: #aaa ;
      border-radius: 3px;
  } */

.modal_liquidity_divs .add-liquidity-popup {
  color: #fff;
  background-color: transparent;
  border-radius: 3px;
  margin: 5px 5px;
  padding: 10px;
  /* border: 1px solid #222; */
}

.modal_liquidity_divs .add-liquidity-popup.active {
  color: #aaaaaa !important;
  background-color: #1a3532 !important;
  border: 1px solid transparent !important;
}
.modal_liquidity_divs .add-liquidity-popup:hover {
  color: #fff !important;
  background-color: #47746e !important;
  border-color: #242422 !important;
  cursor: pointer;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(27 27 27) !important;
  opacity: 1;
}
.liquidity-currency-model {
  max-height: 360px;
  overflow-y: auto;
}
.swap_add_token {
  /* background:#2d3235 ; */
  padding: 3px;
  background: linear-gradient(270deg, #f705d957, #2662b552);
  border-radius: 3px;
  font-size: 10px;
}

.pink_img_bg {
  background: url('/image/bg_2.png');
  /* background-size: cover; */
  /* padding: 2rem 3rem 1rem; */
  /* width: 100%; */
  border-radius: 1rem;
  margin-bottom: 30px;
  background-position: center;
}

.launchpad-card-section {
  padding-top: -20px;
}

.launchpad_main_section {
  padding: 60px 0 36px !important;
}

.boy_img {
  position: absolute;
  right: 15px;
  bottom: 0;
}

.analytice_img img {
  top: 55px;
  position: absolute;
  right: 36px;
}
.prosal_img img {
  top: 7px;
  position: absolute;
  right: 36px;
  width: 200px;
}

#carouselLaunchpad .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.marketplace .react-multi-carousel-dot-list {
  position: absolute;
  top: 15px;
  right: 15px;
  justify-content: end;
  /* display: contents; */
}
.marketplace .react-multi-carousel-dot-list {
  position: absolute;
  top: 88% !important;
  right: 22px;
  justify-content: end;
  /* display: contents; */
}

.plusicon::before {
  content: '\002B';
  position: relative;
  color: #fff;
  font-size: 18px;
  margin: 0 3px;
}
.video_model_prizepool {
  cursor: pointer;
}

.card_bg_img {
  background: url(/public/image/hero_card_img.png);
  width: 180px;
  height: 158px;
  background-size: contain;
  background-position: center center;
}

.card_bg_img .card_text {
  position: relative;
  top: 72px;
  text-align: center;
}

.rounded_circle_icon {
  position: absolute;
  left: -23px;
  top: 3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #3a3835;
  line-height: 30px;
  text-align: center;
}

.circle_loader {
  width: 80px;
  height: 80px;
  background: #000;
  border-radius: 50%;
}
.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  background: black;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.feed_cards .card {
  background: #272a2c;
  margin-bottom: 20px;
}

.seller_img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

